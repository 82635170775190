import unhead_KLMuJ_o5S0KEriTA16LyZc5sWSBsK9H3y4ce1LFFTJQ from "/var/www/html/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_bd657509f2e13cdd85e2311196a0114d/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HSib5eL00JETJn8ctN5OA5Rd8komJg4niDpkWobccDo from "/var/www/html/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_bd657509f2e13cdd85e2311196a0114d/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/var/www/html/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import auth_ydS_uAQ5qS4E2Ez9vBX0d0JPoo00WYZNOgh9_nraCUo from "/var/www/html/plugins/auth.ts";
import boot_yBEouP8Zd3U_megJky1sEVzyPYOXblA7NPZzkhpleDg from "/var/www/html/plugins/boot.ts";
import window_0QW_qtSNNkufGNiEgxEBLTactodUEq33Sl2mkfwpWeQ from "/var/www/html/plugins/window.ts";
import payload_client_fMl9_Kwvh8RoV5ZMRe0RZi1FO69aFwlb_wewUDt9G3Q from "/var/www/html/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_bd657509f2e13cdd85e2311196a0114d/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_klvZOgqpdhhaKcAj_k3r41HXo6nmSrzVbApTQYb4CAI from "/var/www/html/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_bd657509f2e13cdd85e2311196a0114d/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_T47AANvKLUBY1ajD9bi3P7LvXUdctp0i6VHwJtdM_9Y from "/var/www/html/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_bd657509f2e13cdd85e2311196a0114d/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_ZScvSJMwkkhb_DcX4GN_gc6ixIeNSUo5awCs1Qg_XMI from "/var/www/html/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_bd657509f2e13cdd85e2311196a0114d/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ek5u0GQRKrSxX_DIlEizjRugPViAT35oycvDI9WtsqM from "/var/www/html/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.10_jiti@2.4.2_sass@1.86.0_95e30e59affd8addaa721dcc2a6477c6/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_OSyGuwPsTcTUR44LelhUZVEVPRc8RtEOKKF4zOUZ0p0 from "/var/www/html/node_modules/.pnpm/nuxt-auth-sanctum@0.6.1/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
export default [
  unhead_KLMuJ_o5S0KEriTA16LyZc5sWSBsK9H3y4ce1LFFTJQ,
  router_HSib5eL00JETJn8ctN5OA5Rd8komJg4niDpkWobccDo,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  auth_ydS_uAQ5qS4E2Ez9vBX0d0JPoo00WYZNOgh9_nraCUo,
  boot_yBEouP8Zd3U_megJky1sEVzyPYOXblA7NPZzkhpleDg,
  window_0QW_qtSNNkufGNiEgxEBLTactodUEq33Sl2mkfwpWeQ,
  payload_client_fMl9_Kwvh8RoV5ZMRe0RZi1FO69aFwlb_wewUDt9G3Q,
  navigation_repaint_client_klvZOgqpdhhaKcAj_k3r41HXo6nmSrzVbApTQYb4CAI,
  chunk_reload_client_T47AANvKLUBY1ajD9bi3P7LvXUdctp0i6VHwJtdM_9Y,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_ZScvSJMwkkhb_DcX4GN_gc6ixIeNSUo5awCs1Qg_XMI,
  plugin_ek5u0GQRKrSxX_DIlEizjRugPViAT35oycvDI9WtsqM,
  plugin_OSyGuwPsTcTUR44LelhUZVEVPRc8RtEOKKF4zOUZ0p0
]