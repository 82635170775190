// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface String {
    // Already in TS
    // replaceAll: (search: String | string, replacement: String | string) => String | string;
    capitalize: () => String | string;
  }

  interface StringConstructor {
    isString(value: any): boolean;
  }
}

// Already in TS
// String.prototype.replaceAll = function (search: String | string, replacement: String | string): String | string {
//   return this.split(search as string).join(replacement as string);
// };

String.prototype.capitalize = function (): String | string {
  return this[0].toUpperCase() + this.slice(1);
};

String.isString = function (value: any): boolean {
  return ((typeof value === 'string') || (value instanceof String));
};
