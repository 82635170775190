
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexdvYLMKHSgCh0YFvn3yvGLQNPhLIadbDYU0kPUJQ34fQMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as loginqCC3U0W3EvwfsFHRUAjTngIFA1CfOB9U3v2eScPZi7kMeta } from "/var/www/html/pages/login.vue?macro=true";
import { default as contactXv4D0Qm8D7SPgEl3bT9vYciJ6Ud8WK5O_45r5bxurEjU8Meta } from "/var/www/html/pages/contact.vue?macro=true";
import { default as indexXdN_7_45oQa_45CZPqnycAECIYBEMKSGGJz9_454yJFOM4xIwMeta } from "/var/www/html/pages/otp/index.vue?macro=true";
import { default as createO_45_bzxhbA8ggT_45dfLPQNr9nVEbGgC3RU8AAjxk_yuFAMeta } from "/var/www/html/pages/otp/create.vue?macro=true";
import { default as index27YGF1jCrpj8CNh6IDQo45wlKOZpHc4NuIL6Qh2ioEwMeta } from "/var/www/html/pages/role/index.vue?macro=true";
import { default as indexz7fCHqkHyORu5g1A_s6z_qdSKqAmF6DsCp79_wPqNBQMeta } from "/var/www/html/pages/user/index.vue?macro=true";
import { default as createL9zp_ZwLOswLyXqxDY_dw5fNaTya85JPI6TFSm8H5KQMeta } from "/var/www/html/pages/role/create.vue?macro=true";
import { default as createQRVXIJvNYSse28rvw5KRKq4lX7m7QWpVoeg1ML1Y__45YMeta } from "/var/www/html/pages/user/create.vue?macro=true";
import { default as edit_D0hVV3_454J9Nq0MRj3tBWBgRhxkAHhN5qLSfNe9x5xcMeta } from "/var/www/html/pages/otp/[id]/edit.vue?macro=true";
import { default as indexuPYeFS_45soSBJ19XBQiFvbCnJ38HKdp_458HX6RZ_9jVWkMeta } from "/var/www/html/pages/profile/index.vue?macro=true";
import { default as index90Nt5xXmMgR_45gPra_45yCD0NT80OtWhMNG_45tqYKD5t8jEMeta } from "/var/www/html/pages/otp/[id]/index.vue?macro=true";
import { default as indexLydrpraz_45xFRUigPWyEW62AT_u_45huacbEAdPG_45Wo_4wMeta } from "/var/www/html/pages/register/index.vue?macro=true";
import { default as editodCWHD7g90Bp4rGsjMp3oeOkSGMZ9bZN913kp1NmoV8Meta } from "/var/www/html/pages/role/[id]/edit.vue?macro=true";
import { default as edit1sj9o49cLSpQpuIhMLrrtNZzwTRLMG5396wwlYMclJYMeta } from "/var/www/html/pages/user/[id]/edit.vue?macro=true";
import { default as indexiyKazXb4dJTm9ulM9caZ2Oj_gZV4t25HvjXynUZSoBAMeta } from "/var/www/html/pages/zbl/road/index.vue?macro=true";
import { default as indexuCtUQkP3CXII6ehqmw56FOYXWVnhye0btUgLnFHPlDgMeta } from "/var/www/html/pages/role/[id]/index.vue?macro=true";
import { default as indexcGdLsIFQzWL27eUnIMEWsLY5Ie8GWJN7n22iFr5ETtcMeta } from "/var/www/html/pages/user/[id]/index.vue?macro=true";
import { default as createC2dlJadAlXqHSjMbIbWsiZPAtlH4e7hxEi_45zhERo92oMeta } from "/var/www/html/pages/zbl/road/create.vue?macro=true";
import { default as indexhXQRnwJZmlQyP8yEcvMYpxXL2NcxujKyihtw2p50mMAMeta } from "/var/www/html/pages/permission/index.vue?macro=true";
import { default as editCCJKrYsMwIUMJ0Uu8N0hd9aCz6jwQAEoEdvgLkCwyJ8Meta } from "/var/www/html/pages/organization/edit.vue?macro=true";
import { default as indexbzsSzmNz5Snw_452XWooalflI8_45LL0bjudFhhZBu0n5rkMeta } from "/var/www/html/pages/otp/carrier/index.vue?macro=true";
import { default as indexxisCgd5AWuV4T0SsaWQdVRTensaAldLij4RIyWny1ZcMeta } from "/var/www/html/pages/otp/request/index.vue?macro=true";
import { default as indexLtFEd5cbOhW6wf6368L8bRWWigon35gtsgWYx9awqaYMeta } from "/var/www/html/pages/ssc/section/index.vue?macro=true";
import { default as index5k_Jl3Z53w3_45kyr7fpnitBV_45RF8ZmD8TYZM4S8l6XrQMeta } from "/var/www/html/pages/zbl/section/index.vue?macro=true";
import { default as indexbsmYawzYFRndTeZ4dduXoDKo9RECrr8XYPufg_45S6PT4Meta } from "/var/www/html/pages/organization/index.vue?macro=true";
import { default as signatureMQbYWalyMHpVKF_45ASHTXp_45QPT8I3r8s1aGZdCGM1Uk0Meta } from "/var/www/html/pages/otp/[id]/signature.vue?macro=true";
import { default as create3_456Rq76Q_VCPzL6xQfIWHB8R12hXbpbTOwkU6_45HDl7UMeta } from "/var/www/html/pages/otp/carrier/create.vue?macro=true";
import { default as indexk5Te_45DJ3I4D9F87nQmBxbGx8A9qqUF1IB8UTWPCMYfgMeta } from "/var/www/html/pages/otp/overview/index.vue?macro=true";
import { default as create50MQtBttRW9fInvcvtCUPnHlbXRkkl_dXWzDqT72ejYMeta } from "/var/www/html/pages/otp/request/create.vue?macro=true";
import { default as _91id_93Q6DRQc5jxQF_45qBZ_HZJD5ugJBe1kpEFnNJQgdRtPNLcMeta } from "/var/www/html/pages/profile/reset/[id].vue?macro=true";
import { default as createnpyee8DBdIHTu_xsd_ouYRT0Nyy9jE6uZJQudEqn59sMeta } from "/var/www/html/pages/ssc/section/create.vue?macro=true";
import { default as editWsUx0FyNBsx7VztIHCEZSU3P9YDVvkhbXLwV7TsaDyIMeta } from "/var/www/html/pages/zbl/road/[id]/edit.vue?macro=true";
import { default as create3q2tvSSr2iQ9U2b066Kr5OqbM2S4ObIzU9ENip8RULsMeta } from "/var/www/html/pages/zbl/section/create.vue?macro=true";
import { default as createJs9mQ4SdmZPmKIgvYkDbHpEouBA7kN8eEoU_4575aKDMoMeta } from "/var/www/html/pages/organization/create.vue?macro=true";
import { default as indexbFFD35gAIOBloEhlZgH0_3QanuWqcljvBrjsDUh6D3MMeta } from "/var/www/html/pages/otp/signature/index.vue?macro=true";
import { default as objectES5A_i32Yn40HgKKIr_45e8ngkcCD_Yq4bhnS27G_ZebEMeta } from "/var/www/html/pages/register/ssc/object.vue?macro=true";
import { default as indexVb4dC5_451lw2yub2UABoYP6N_167eyo0RqOPG4cJKYVgMeta } from "/var/www/html/pages/zbl/crossroad/index.vue?macro=true";
import { default as indexcSDI69vituuIn0nb98AK7MEccCSRup4jouC__dfEqWMMeta } from "/var/www/html/pages/zbl/road/[id]/index.vue?macro=true";
import { default as indexpBHQqt2j8yDB7ONFLVgX6GcsGnT4C_s_1_45baeMp0aswMeta } from "/var/www/html/pages/otp/attachment/index.vue?macro=true";
import { default as create2hHREufdQ6TsowcplAukZx1dvjMfwwe7lf0Zhtxw9OQMeta } from "/var/www/html/pages/otp/signature/create.vue?macro=true";
import { default as editj3GF3588mxxuJVKLSVk3oLgPX74mifQC4XmLmD4_45DdcMeta } from "/var/www/html/pages/permission/[id]/edit.vue?macro=true";
import { default as sectionjwQH9fl1pqGQ5amZfJabcqiMEgVXmJYJxXrp6ugDdvYMeta } from "/var/www/html/pages/register/ssc/section.vue?macro=true";
import { default as sectionvaHH2zbOwMRhtrTvs0GhuOotopGVbcVnilKiOgqR6pYMeta } from "/var/www/html/pages/register/zbl/section.vue?macro=true";
import { default as createp4nUTCisdAZTwKq9sopBV3_fXpUJBUKvRUKO0XHvUm0Meta } from "/var/www/html/pages/zbl/crossroad/create.vue?macro=true";
import { default as edit_Od_45Uuc0yAtnOp_r6Oj8V58_45lPjs782AwTO5PfbwNLYMeta } from "/var/www/html/pages/otp/carrier/[id]/edit.vue?macro=true";
import { default as edit2CfeVcAokWdtZwrv3cCXF7HuCDP8iiYNK4Jx4ou6wN4Meta } from "/var/www/html/pages/otp/request/[id]/edit.vue?macro=true";
import { default as indexXlNRrH6pmfbCJwYZBJTS4TvZwvs1jMM4Wfx7jpDMM1EMeta } from "/var/www/html/pages/permission/[id]/index.vue?macro=true";
import { default as indexyA7DhIGE4cqi8OgeI2vjInI0SxIRBhrbSMCHGPGfmcIMeta } from "/var/www/html/pages/profile/carrier/index.vue?macro=true";
import { default as editoJZSNLqXjN5bL_8tAEGTijN8vXAJpJ8uqH_45LXJdcb9EMeta } from "/var/www/html/pages/ssc/section/[id]/edit.vue?macro=true";
import { default as editMK0lSeX3N6MjoFxcceS5gs7Xq48DgPss8rcnTjM2xlcMeta } from "/var/www/html/pages/zbl/section/[id]/edit.vue?macro=true";
import { default as editmBIG7TctUedZHKwiYYCy1iT7QhAbZHon2U5Q_45G_ivAcMeta } from "/var/www/html/pages/organization/[id]/edit.vue?macro=true";
import { default as indexmcZeI8D_45hgmAclnuYvmFyFGVPr_45w9OfWJZU8120kSJMMeta } from "/var/www/html/pages/otp/carrier/[id]/index.vue?macro=true";
import { default as indexR07pTfQkDjsVNy_45GmjDgKvMSJ376lYlQdFlQQ9UYSx8Meta } from "/var/www/html/pages/otp/request/[id]/index.vue?macro=true";
import { default as create6JC78Hsi_452CLiD9MsGXomkgjO5VwLpD_IMgmaAJqhiQMeta } from "/var/www/html/pages/profile/carrier/create.vue?macro=true";
import { default as crossroadN8zifrwDRd5NVGkA_Tl6hCwatfLENKwEtywsr7tuWgAMeta } from "/var/www/html/pages/register/zbl/crossroad.vue?macro=true";
import { default as indexXVMKsjAjR_45NR5EOWCJYGW_45nUrcJXvJs7BXRDx9j9o4AMeta } from "/var/www/html/pages/ssc/section/[id]/index.vue?macro=true";
import { default as indexiIqzOm3sC5Dry28_gwdiKCpW8IqqXlB4aRlfBotKxK0Meta } from "/var/www/html/pages/zbl/section/[id]/index.vue?macro=true";
import { default as indexUVq9PITq5MxPRjT4pepwsKUlvKGFY3zLp6VKHkqtN5kMeta } from "/var/www/html/pages/organization/[id]/index.vue?macro=true";
import { default as edit3zQNIwl4IBDRiWOQArRs_45WsJzdBbd1rPfNOa9sD1sQEMeta } from "/var/www/html/pages/otp/signature/[id]/edit.vue?macro=true";
import { default as stationingVo2ULQDY6_57FPaj9QUrfKTEZfMZSg6BKYRb2WoG_nUMeta } from "/var/www/html/pages/register/zbl/stationing.vue?macro=true";
import { default as editHVQCbHxRjmLVWc8RspR8xV1FsMtjAnRol5cOAx3GrcEMeta } from "/var/www/html/pages/zbl/crossroad/[id]/edit.vue?macro=true";
import { default as indexkZM2520ySk4Yl_G_mCLiChGfCvvDZiRZhpAnhD_45dZTIMeta } from "/var/www/html/pages/zbl/crossroad/way/index.vue?macro=true";
import { default as edit0t24eI4qaaJfAdZNpGDcs_45_fBuRj1Cxjyx0Dac16HxgMeta } from "/var/www/html/pages/otp/attachment/[id]/edit.vue?macro=true";
import { default as resolvesqBnsRtv9EamPpGji2AapBRpr7RUKBmjoi4eiVQ02UEMeta } from "/var/www/html/pages/otp/request/[id]/resolve.vue?macro=true";
import { default as indexAJvtgIswVpPN0wxGutN0M_22u1umwapoomypr_0HjzkMeta } from "/var/www/html/pages/otp/signature/[id]/index.vue?macro=true";
import { default as index2jaGC5mPPEoDCDpjAhUKBvI046EE63SyMDN_eEZO0VMMeta } from "/var/www/html/pages/ssc/section/object/index.vue?macro=true";
import { default as indexW0HCIAcwKtPG2ZHGrUjoo8M1oL4NIgvU6Q8mS6YSDLMMeta } from "/var/www/html/pages/zbl/crossroad/[id]/index.vue?macro=true";
import { default as createQYOFU8PwVp29DhiC3QOCawr_4O71UZFhpHsL1voS_A0Meta } from "/var/www/html/pages/zbl/crossroad/way/create.vue?macro=true";
import { default as index7YtvC8CR1Gf1_45o82_45baGqOSpAE48RH7WjeNR3xKQpAMMeta } from "/var/www/html/pages/otp/attachment/[id]/index.vue?macro=true";
import { default as editO9j9Xcw8Qk_Sfvve91Psr_45sCR0Bt65Lt_45g41vlcWDZEMeta } from "/var/www/html/pages/profile/carrier/[id]/edit.vue?macro=true";
import { default as createTvPeuTIZpE5hxNzXwlHAfJvJC28wY7Zf7WFEWQDfZOoMeta } from "/var/www/html/pages/ssc/section/object/create.vue?macro=true";
import { default as index3QVTOz2GAAwnpsL1cFlI8kx7CaBXfJilDD94iaqGKpUMeta } from "/var/www/html/pages/profile/carrier/[id]/index.vue?macro=true";
import { default as editZDdouHwU5zdmxddlTz1sdNCTTcWLR_4GjfGDoB5iuRAMeta } from "/var/www/html/pages/zbl/crossroad/way/[id]/edit.vue?macro=true";
import { default as editFBPS5bonRbH0QxuxKgAiy6xcfMz_45h_45UxQBFAufRwU60Meta } from "/var/www/html/pages/ssc/section/object/[id]/edit.vue?macro=true";
import { default as indexOAjQuguBWitXQRgOPfO4tEo_45aE_CXW_452NBsfMYzSiKYMeta } from "/var/www/html/pages/zbl/crossroad/way/[id]/index.vue?macro=true";
import { default as indexU_45mU5WCg_sztJMH4SjA9kjCN_tKmaCvUJ3wMdWEAQDQMeta } from "/var/www/html/pages/ssc/section/object/[id]/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexdvYLMKHSgCh0YFvn3yvGLQNPhLIadbDYU0kPUJQ34fQMeta || {},
    component: () => import("/var/www/html/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginqCC3U0W3EvwfsFHRUAjTngIFA1CfOB9U3v2eScPZi7kMeta || {},
    component: () => import("/var/www/html/pages/login.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactXv4D0Qm8D7SPgEl3bT9vYciJ6Ud8WK5O_45r5bxurEjU8Meta || {},
    component: () => import("/var/www/html/pages/contact.vue")
  },
  {
    name: "otp",
    path: "/otp",
    meta: indexXdN_7_45oQa_45CZPqnycAECIYBEMKSGGJz9_454yJFOM4xIwMeta || {},
    component: () => import("/var/www/html/pages/otp/index.vue")
  },
  {
    name: "otp-create",
    path: "/otp/create",
    meta: createO_45_bzxhbA8ggT_45dfLPQNr9nVEbGgC3RU8AAjxk_yuFAMeta || {},
    component: () => import("/var/www/html/pages/otp/create.vue")
  },
  {
    name: "role",
    path: "/role",
    meta: index27YGF1jCrpj8CNh6IDQo45wlKOZpHc4NuIL6Qh2ioEwMeta || {},
    component: () => import("/var/www/html/pages/role/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: indexz7fCHqkHyORu5g1A_s6z_qdSKqAmF6DsCp79_wPqNBQMeta || {},
    component: () => import("/var/www/html/pages/user/index.vue")
  },
  {
    name: "role-create",
    path: "/role/create",
    meta: createL9zp_ZwLOswLyXqxDY_dw5fNaTya85JPI6TFSm8H5KQMeta || {},
    component: () => import("/var/www/html/pages/role/create.vue")
  },
  {
    name: "user-create",
    path: "/user/create",
    meta: createQRVXIJvNYSse28rvw5KRKq4lX7m7QWpVoeg1ML1Y__45YMeta || {},
    component: () => import("/var/www/html/pages/user/create.vue")
  },
  {
    name: "otp-id-edit",
    path: "/otp/:id()/edit",
    meta: edit_D0hVV3_454J9Nq0MRj3tBWBgRhxkAHhN5qLSfNe9x5xcMeta || {},
    component: () => import("/var/www/html/pages/otp/[id]/edit.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexuPYeFS_45soSBJ19XBQiFvbCnJ38HKdp_458HX6RZ_9jVWkMeta || {},
    component: () => import("/var/www/html/pages/profile/index.vue")
  },
  {
    name: "otp-id",
    path: "/otp/:id()",
    meta: index90Nt5xXmMgR_45gPra_45yCD0NT80OtWhMNG_45tqYKD5t8jEMeta || {},
    component: () => import("/var/www/html/pages/otp/[id]/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: indexLydrpraz_45xFRUigPWyEW62AT_u_45huacbEAdPG_45Wo_4wMeta || {},
    component: () => import("/var/www/html/pages/register/index.vue")
  },
  {
    name: "role-id-edit",
    path: "/role/:id()/edit",
    meta: editodCWHD7g90Bp4rGsjMp3oeOkSGMZ9bZN913kp1NmoV8Meta || {},
    component: () => import("/var/www/html/pages/role/[id]/edit.vue")
  },
  {
    name: "user-id-edit",
    path: "/user/:id()/edit",
    meta: edit1sj9o49cLSpQpuIhMLrrtNZzwTRLMG5396wwlYMclJYMeta || {},
    component: () => import("/var/www/html/pages/user/[id]/edit.vue")
  },
  {
    name: "zbl-road",
    path: "/zbl/road",
    meta: indexiyKazXb4dJTm9ulM9caZ2Oj_gZV4t25HvjXynUZSoBAMeta || {},
    component: () => import("/var/www/html/pages/zbl/road/index.vue")
  },
  {
    name: "role-id",
    path: "/role/:id()",
    meta: indexuCtUQkP3CXII6ehqmw56FOYXWVnhye0btUgLnFHPlDgMeta || {},
    component: () => import("/var/www/html/pages/role/[id]/index.vue")
  },
  {
    name: "user-id",
    path: "/user/:id()",
    meta: indexcGdLsIFQzWL27eUnIMEWsLY5Ie8GWJN7n22iFr5ETtcMeta || {},
    component: () => import("/var/www/html/pages/user/[id]/index.vue")
  },
  {
    name: "zbl-road-create",
    path: "/zbl/road/create",
    meta: createC2dlJadAlXqHSjMbIbWsiZPAtlH4e7hxEi_45zhERo92oMeta || {},
    component: () => import("/var/www/html/pages/zbl/road/create.vue")
  },
  {
    name: "permission",
    path: "/permission",
    meta: indexhXQRnwJZmlQyP8yEcvMYpxXL2NcxujKyihtw2p50mMAMeta || {},
    component: () => import("/var/www/html/pages/permission/index.vue")
  },
  {
    name: "organization-edit",
    path: "/organization/edit",
    meta: editCCJKrYsMwIUMJ0Uu8N0hd9aCz6jwQAEoEdvgLkCwyJ8Meta || {},
    component: () => import("/var/www/html/pages/organization/edit.vue")
  },
  {
    name: "otp-carrier",
    path: "/otp/carrier",
    meta: indexbzsSzmNz5Snw_452XWooalflI8_45LL0bjudFhhZBu0n5rkMeta || {},
    component: () => import("/var/www/html/pages/otp/carrier/index.vue")
  },
  {
    name: "otp-request",
    path: "/otp/request",
    meta: indexxisCgd5AWuV4T0SsaWQdVRTensaAldLij4RIyWny1ZcMeta || {},
    component: () => import("/var/www/html/pages/otp/request/index.vue")
  },
  {
    name: "ssc-section",
    path: "/ssc/section",
    meta: indexLtFEd5cbOhW6wf6368L8bRWWigon35gtsgWYx9awqaYMeta || {},
    component: () => import("/var/www/html/pages/ssc/section/index.vue")
  },
  {
    name: "zbl-section",
    path: "/zbl/section",
    meta: index5k_Jl3Z53w3_45kyr7fpnitBV_45RF8ZmD8TYZM4S8l6XrQMeta || {},
    component: () => import("/var/www/html/pages/zbl/section/index.vue")
  },
  {
    name: "organization",
    path: "/organization",
    meta: indexbsmYawzYFRndTeZ4dduXoDKo9RECrr8XYPufg_45S6PT4Meta || {},
    component: () => import("/var/www/html/pages/organization/index.vue")
  },
  {
    name: "otp-id-signature",
    path: "/otp/:id()/signature",
    meta: signatureMQbYWalyMHpVKF_45ASHTXp_45QPT8I3r8s1aGZdCGM1Uk0Meta || {},
    component: () => import("/var/www/html/pages/otp/[id]/signature.vue")
  },
  {
    name: "otp-carrier-create",
    path: "/otp/carrier/create",
    meta: create3_456Rq76Q_VCPzL6xQfIWHB8R12hXbpbTOwkU6_45HDl7UMeta || {},
    component: () => import("/var/www/html/pages/otp/carrier/create.vue")
  },
  {
    name: "otp-overview",
    path: "/otp/overview",
    meta: indexk5Te_45DJ3I4D9F87nQmBxbGx8A9qqUF1IB8UTWPCMYfgMeta || {},
    component: () => import("/var/www/html/pages/otp/overview/index.vue")
  },
  {
    name: "otp-request-create",
    path: "/otp/request/create",
    meta: create50MQtBttRW9fInvcvtCUPnHlbXRkkl_dXWzDqT72ejYMeta || {},
    component: () => import("/var/www/html/pages/otp/request/create.vue")
  },
  {
    name: "profile-reset-id",
    path: "/profile/reset/:id()",
    meta: _91id_93Q6DRQc5jxQF_45qBZ_HZJD5ugJBe1kpEFnNJQgdRtPNLcMeta || {},
    component: () => import("/var/www/html/pages/profile/reset/[id].vue")
  },
  {
    name: "ssc-section-create",
    path: "/ssc/section/create",
    meta: createnpyee8DBdIHTu_xsd_ouYRT0Nyy9jE6uZJQudEqn59sMeta || {},
    component: () => import("/var/www/html/pages/ssc/section/create.vue")
  },
  {
    name: "zbl-road-id-edit",
    path: "/zbl/road/:id()/edit",
    meta: editWsUx0FyNBsx7VztIHCEZSU3P9YDVvkhbXLwV7TsaDyIMeta || {},
    component: () => import("/var/www/html/pages/zbl/road/[id]/edit.vue")
  },
  {
    name: "zbl-section-create",
    path: "/zbl/section/create",
    meta: create3q2tvSSr2iQ9U2b066Kr5OqbM2S4ObIzU9ENip8RULsMeta || {},
    component: () => import("/var/www/html/pages/zbl/section/create.vue")
  },
  {
    name: "organization-create",
    path: "/organization/create",
    meta: createJs9mQ4SdmZPmKIgvYkDbHpEouBA7kN8eEoU_4575aKDMoMeta || {},
    component: () => import("/var/www/html/pages/organization/create.vue")
  },
  {
    name: "otp-signature",
    path: "/otp/signature",
    meta: indexbFFD35gAIOBloEhlZgH0_3QanuWqcljvBrjsDUh6D3MMeta || {},
    component: () => import("/var/www/html/pages/otp/signature/index.vue")
  },
  {
    name: "register-ssc-object",
    path: "/register/ssc/object",
    meta: objectES5A_i32Yn40HgKKIr_45e8ngkcCD_Yq4bhnS27G_ZebEMeta || {},
    component: () => import("/var/www/html/pages/register/ssc/object.vue")
  },
  {
    name: "zbl-crossroad",
    path: "/zbl/crossroad",
    meta: indexVb4dC5_451lw2yub2UABoYP6N_167eyo0RqOPG4cJKYVgMeta || {},
    component: () => import("/var/www/html/pages/zbl/crossroad/index.vue")
  },
  {
    name: "zbl-road-id",
    path: "/zbl/road/:id()",
    meta: indexcSDI69vituuIn0nb98AK7MEccCSRup4jouC__dfEqWMMeta || {},
    component: () => import("/var/www/html/pages/zbl/road/[id]/index.vue")
  },
  {
    name: "otp-attachment",
    path: "/otp/attachment",
    meta: indexpBHQqt2j8yDB7ONFLVgX6GcsGnT4C_s_1_45baeMp0aswMeta || {},
    component: () => import("/var/www/html/pages/otp/attachment/index.vue")
  },
  {
    name: "otp-signature-create",
    path: "/otp/signature/create",
    meta: create2hHREufdQ6TsowcplAukZx1dvjMfwwe7lf0Zhtxw9OQMeta || {},
    component: () => import("/var/www/html/pages/otp/signature/create.vue")
  },
  {
    name: "permission-id-edit",
    path: "/permission/:id()/edit",
    meta: editj3GF3588mxxuJVKLSVk3oLgPX74mifQC4XmLmD4_45DdcMeta || {},
    component: () => import("/var/www/html/pages/permission/[id]/edit.vue")
  },
  {
    name: "register-ssc-section",
    path: "/register/ssc/section",
    meta: sectionjwQH9fl1pqGQ5amZfJabcqiMEgVXmJYJxXrp6ugDdvYMeta || {},
    component: () => import("/var/www/html/pages/register/ssc/section.vue")
  },
  {
    name: "register-zbl-section",
    path: "/register/zbl/section",
    meta: sectionvaHH2zbOwMRhtrTvs0GhuOotopGVbcVnilKiOgqR6pYMeta || {},
    component: () => import("/var/www/html/pages/register/zbl/section.vue")
  },
  {
    name: "zbl-crossroad-create",
    path: "/zbl/crossroad/create",
    meta: createp4nUTCisdAZTwKq9sopBV3_fXpUJBUKvRUKO0XHvUm0Meta || {},
    component: () => import("/var/www/html/pages/zbl/crossroad/create.vue")
  },
  {
    name: "otp-carrier-id-edit",
    path: "/otp/carrier/:id()/edit",
    meta: edit_Od_45Uuc0yAtnOp_r6Oj8V58_45lPjs782AwTO5PfbwNLYMeta || {},
    component: () => import("/var/www/html/pages/otp/carrier/[id]/edit.vue")
  },
  {
    name: "otp-request-id-edit",
    path: "/otp/request/:id()/edit",
    meta: edit2CfeVcAokWdtZwrv3cCXF7HuCDP8iiYNK4Jx4ou6wN4Meta || {},
    component: () => import("/var/www/html/pages/otp/request/[id]/edit.vue")
  },
  {
    name: "permission-id",
    path: "/permission/:id()",
    meta: indexXlNRrH6pmfbCJwYZBJTS4TvZwvs1jMM4Wfx7jpDMM1EMeta || {},
    component: () => import("/var/www/html/pages/permission/[id]/index.vue")
  },
  {
    name: "profile-carrier",
    path: "/profile/carrier",
    meta: indexyA7DhIGE4cqi8OgeI2vjInI0SxIRBhrbSMCHGPGfmcIMeta || {},
    component: () => import("/var/www/html/pages/profile/carrier/index.vue")
  },
  {
    name: "ssc-section-id-edit",
    path: "/ssc/section/:id()/edit",
    meta: editoJZSNLqXjN5bL_8tAEGTijN8vXAJpJ8uqH_45LXJdcb9EMeta || {},
    component: () => import("/var/www/html/pages/ssc/section/[id]/edit.vue")
  },
  {
    name: "zbl-section-id-edit",
    path: "/zbl/section/:id()/edit",
    meta: editMK0lSeX3N6MjoFxcceS5gs7Xq48DgPss8rcnTjM2xlcMeta || {},
    component: () => import("/var/www/html/pages/zbl/section/[id]/edit.vue")
  },
  {
    name: "organization-id-edit",
    path: "/organization/:id()/edit",
    meta: editmBIG7TctUedZHKwiYYCy1iT7QhAbZHon2U5Q_45G_ivAcMeta || {},
    component: () => import("/var/www/html/pages/organization/[id]/edit.vue")
  },
  {
    name: "otp-carrier-id",
    path: "/otp/carrier/:id()",
    meta: indexmcZeI8D_45hgmAclnuYvmFyFGVPr_45w9OfWJZU8120kSJMMeta || {},
    component: () => import("/var/www/html/pages/otp/carrier/[id]/index.vue")
  },
  {
    name: "otp-request-id",
    path: "/otp/request/:id()",
    meta: indexR07pTfQkDjsVNy_45GmjDgKvMSJ376lYlQdFlQQ9UYSx8Meta || {},
    component: () => import("/var/www/html/pages/otp/request/[id]/index.vue")
  },
  {
    name: "profile-carrier-create",
    path: "/profile/carrier/create",
    meta: create6JC78Hsi_452CLiD9MsGXomkgjO5VwLpD_IMgmaAJqhiQMeta || {},
    component: () => import("/var/www/html/pages/profile/carrier/create.vue")
  },
  {
    name: "register-zbl-crossroad",
    path: "/register/zbl/crossroad",
    meta: crossroadN8zifrwDRd5NVGkA_Tl6hCwatfLENKwEtywsr7tuWgAMeta || {},
    component: () => import("/var/www/html/pages/register/zbl/crossroad.vue")
  },
  {
    name: "ssc-section-id",
    path: "/ssc/section/:id()",
    meta: indexXVMKsjAjR_45NR5EOWCJYGW_45nUrcJXvJs7BXRDx9j9o4AMeta || {},
    component: () => import("/var/www/html/pages/ssc/section/[id]/index.vue")
  },
  {
    name: "zbl-section-id",
    path: "/zbl/section/:id()",
    meta: indexiIqzOm3sC5Dry28_gwdiKCpW8IqqXlB4aRlfBotKxK0Meta || {},
    component: () => import("/var/www/html/pages/zbl/section/[id]/index.vue")
  },
  {
    name: "organization-id",
    path: "/organization/:id()",
    meta: indexUVq9PITq5MxPRjT4pepwsKUlvKGFY3zLp6VKHkqtN5kMeta || {},
    component: () => import("/var/www/html/pages/organization/[id]/index.vue")
  },
  {
    name: "otp-signature-id-edit",
    path: "/otp/signature/:id()/edit",
    meta: edit3zQNIwl4IBDRiWOQArRs_45WsJzdBbd1rPfNOa9sD1sQEMeta || {},
    component: () => import("/var/www/html/pages/otp/signature/[id]/edit.vue")
  },
  {
    name: "register-zbl-stationing",
    path: "/register/zbl/stationing",
    meta: stationingVo2ULQDY6_57FPaj9QUrfKTEZfMZSg6BKYRb2WoG_nUMeta || {},
    component: () => import("/var/www/html/pages/register/zbl/stationing.vue")
  },
  {
    name: "zbl-crossroad-id-edit",
    path: "/zbl/crossroad/:id()/edit",
    meta: editHVQCbHxRjmLVWc8RspR8xV1FsMtjAnRol5cOAx3GrcEMeta || {},
    component: () => import("/var/www/html/pages/zbl/crossroad/[id]/edit.vue")
  },
  {
    name: "zbl-crossroad-way",
    path: "/zbl/crossroad/way",
    meta: indexkZM2520ySk4Yl_G_mCLiChGfCvvDZiRZhpAnhD_45dZTIMeta || {},
    component: () => import("/var/www/html/pages/zbl/crossroad/way/index.vue")
  },
  {
    name: "otp-attachment-id-edit",
    path: "/otp/attachment/:id()/edit",
    meta: edit0t24eI4qaaJfAdZNpGDcs_45_fBuRj1Cxjyx0Dac16HxgMeta || {},
    component: () => import("/var/www/html/pages/otp/attachment/[id]/edit.vue")
  },
  {
    name: "otp-request-id-resolve",
    path: "/otp/request/:id()/resolve",
    meta: resolvesqBnsRtv9EamPpGji2AapBRpr7RUKBmjoi4eiVQ02UEMeta || {},
    component: () => import("/var/www/html/pages/otp/request/[id]/resolve.vue")
  },
  {
    name: "otp-signature-id",
    path: "/otp/signature/:id()",
    meta: indexAJvtgIswVpPN0wxGutN0M_22u1umwapoomypr_0HjzkMeta || {},
    component: () => import("/var/www/html/pages/otp/signature/[id]/index.vue")
  },
  {
    name: "ssc-section-object",
    path: "/ssc/section/object",
    meta: index2jaGC5mPPEoDCDpjAhUKBvI046EE63SyMDN_eEZO0VMMeta || {},
    component: () => import("/var/www/html/pages/ssc/section/object/index.vue")
  },
  {
    name: "zbl-crossroad-id",
    path: "/zbl/crossroad/:id()",
    meta: indexW0HCIAcwKtPG2ZHGrUjoo8M1oL4NIgvU6Q8mS6YSDLMMeta || {},
    component: () => import("/var/www/html/pages/zbl/crossroad/[id]/index.vue")
  },
  {
    name: "zbl-crossroad-way-create",
    path: "/zbl/crossroad/way/create",
    meta: createQYOFU8PwVp29DhiC3QOCawr_4O71UZFhpHsL1voS_A0Meta || {},
    component: () => import("/var/www/html/pages/zbl/crossroad/way/create.vue")
  },
  {
    name: "otp-attachment-id",
    path: "/otp/attachment/:id()",
    meta: index7YtvC8CR1Gf1_45o82_45baGqOSpAE48RH7WjeNR3xKQpAMMeta || {},
    component: () => import("/var/www/html/pages/otp/attachment/[id]/index.vue")
  },
  {
    name: "profile-carrier-id-edit",
    path: "/profile/carrier/:id()/edit",
    meta: editO9j9Xcw8Qk_Sfvve91Psr_45sCR0Bt65Lt_45g41vlcWDZEMeta || {},
    component: () => import("/var/www/html/pages/profile/carrier/[id]/edit.vue")
  },
  {
    name: "ssc-section-object-create",
    path: "/ssc/section/object/create",
    meta: createTvPeuTIZpE5hxNzXwlHAfJvJC28wY7Zf7WFEWQDfZOoMeta || {},
    component: () => import("/var/www/html/pages/ssc/section/object/create.vue")
  },
  {
    name: "profile-carrier-id",
    path: "/profile/carrier/:id()",
    meta: index3QVTOz2GAAwnpsL1cFlI8kx7CaBXfJilDD94iaqGKpUMeta || {},
    component: () => import("/var/www/html/pages/profile/carrier/[id]/index.vue")
  },
  {
    name: "zbl-crossroad-way-id-edit",
    path: "/zbl/crossroad/way/:id()/edit",
    meta: editZDdouHwU5zdmxddlTz1sdNCTTcWLR_4GjfGDoB5iuRAMeta || {},
    component: () => import("/var/www/html/pages/zbl/crossroad/way/[id]/edit.vue")
  },
  {
    name: "ssc-section-object-id-edit",
    path: "/ssc/section/object/:id()/edit",
    meta: editFBPS5bonRbH0QxuxKgAiy6xcfMz_45h_45UxQBFAufRwU60Meta || {},
    component: () => import("/var/www/html/pages/ssc/section/object/[id]/edit.vue")
  },
  {
    name: "zbl-crossroad-way-id",
    path: "/zbl/crossroad/way/:id()",
    meta: indexOAjQuguBWitXQRgOPfO4tEo_45aE_CXW_452NBsfMYzSiKYMeta || {},
    component: () => import("/var/www/html/pages/zbl/crossroad/way/[id]/index.vue")
  },
  {
    name: "ssc-section-object-id",
    path: "/ssc/section/object/:id()",
    meta: indexU_45mU5WCg_sztJMH4SjA9kjCN_tKmaCvUJ3wMdWEAQDQMeta || {},
    component: () => import("/var/www/html/pages/ssc/section/object/[id]/index.vue")
  }
]