import { FetchError } from 'ofetch';

interface RequestBEConfig {
  cache?: 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | 'only-if-cached' | boolean;
  method?: String | string | null;
  headers?: Object | null;
  body?: Object | null;
  params?: Object | null;
  signal?: AbortSignal | null;
}

// Load link into the page header
export default async function (route: String | string, method?: String | string | null, body?: Object | null, headers?: Object | null, params?: Object | null, config?: RequestBEConfig | null) {
  let content = (config ?? {});
  content.method = (method ?? (content.method ? content.method : 'GET'));
  content.headers = (headers ? JSON.parse(JSON.stringify(headers)) : content.headers);
  content.body = (body ? JSON.parse(JSON.stringify(body)) : content.body);
  content.params = (params ? JSON.parse(JSON.stringify(params)) : content.params);
  const client = useSanctumClient();
  try {
    return await client(('api/' + route), content);
  }
  catch (e) {
    if ((e instanceof FetchError) && ((e as FetchError).response?.status === 422)) {
      console.error((e as FetchError).response?._data?.errors);
      return e;
    }
    throw(e);
  }
};
