import '#imports';

export default defineNuxtPlugin({
  name: 'auth',
  dependsOn: [
    'nuxt:global-components',
    'nuxt:router',
    'nuxt-site-config:init',
  ],
  enforce: 'pre', // or 'post'
  //parallel: true,
  async setup(nuxtApp: any) {
    const user = computed(() => {
      const value = new Auth(useSanctumUser<IUser>()?.value ?? useState(useRuntimeConfig()?.public?.sanctum?.userStateKey)?.value);
      if (!value?.logged())
        value?.refresh();
      return value;
    });
    const refresh = async function (): Promise<any> {
      const value = await user;
      if (!value?.value?.logged())
        await value?.value?.refresh();
      return value?.value;
    }
    const call = async function (): Promise<any> {
      const nuxtApp = useNuxtApp();
      let auth = null;
      try {
        auth = await refresh();
        // the Vue/Nuxt compiler loses context here because of the try/catch block.
      } catch (e) {
        auth = null
      }
      if (!auth) {
        // apply the correct Nuxt context to our `navigateTo` call.
        return nuxtApp.runWithContext(() => navigateTo(useSanctumConfig?.redirect?.onAuthOnly ?? '/'));
      }
      return auth?.logged();
    }
    addRouteMiddleware('session', async (to: any, from: any) => {
      return await call();
    });
    addRouteMiddleware('permission', async (to: any, from: any) => {
      const resp = await call();
      if (resp !== true)
        return resp;
      const auth = await refresh();
      return auth?.routeAvailable(to);
    });
    // await useSanctumAuth().init();
    // this is the equivalent of a normal functional plugin
    await nuxtApp.vueApp?.use({
      install(app: any, options: any) {
        app.provide('auth', user);
        // Just in case add check on load...
        app.mixin({
          created() {},
          mounted() {},
        });
      },
    });
    return {
      provide: {
        auth: user
      },
    };
  },
  hooks: {
    // You can directly register Nuxt app runtime hooks here
    // 'app:created'() {
    //   const nuxtApp = useNuxtApp()
    //   // do something in the hook
    // }
  },
  env: {
    // Set this value to `false` if you don't want the plugin to run when rendering server-only or island components.
    islands: true,
  },
});
